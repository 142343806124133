<template>
  <div>
    <h3>
      Your order has been submitted.
    </h3>

    <p>
      Thank you for using our business services!
    </p>

    <br>

    <h3>
      Phone Service Information
    </h3>

    <p>
      Click the Set Up My Phone link below.
    </p>

    <ul>
      <li>
        Select an area code, then choose from several available phone numbers.
      </li>
      <li>
        Give your phone line a name.
      </li>
      <li>
        Tell us how you’d like to receive inbound calls.
      </li>
    </ul>

    <p>
      You can fully customize your Phone Service by navigating to the Phones tab in your online
      account, then clicking on Settings. Here you’ll be able to set up your voicemail, caller ID
      and more.
    </p>

    <p>
      If you have any further questions, please reach out to us at {{ website.emails[0] }}.
      We’re happy to help.
    </p>

    <p>
      Thank you for choosing {{ website.name }}.
    </p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'PhoneServiceOrderConfirmation',

  computed: {
    ...mapGetters('website', ['website']),
  },
}
</script>


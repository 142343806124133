var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("h3", [_vm._v("\n    Your order has been submitted.\n  ")]),
    _c("p", [_vm._v("\n    Thank you for using our business services!\n  ")]),
    _c("br"),
    _c("h3", [_vm._v("\n    Phone Service Information\n  ")]),
    _c("p", [_vm._v("\n    Click the Set Up My Phone link below.\n  ")]),
    _vm._m(0),
    _c("p", [
      _vm._v(
        "\n    You can fully customize your Phone Service by navigating to the Phones tab in your online\n    account, then clicking on Settings. Here you’ll be able to set up your voicemail, caller ID\n    and more.\n  "
      ),
    ]),
    _c("p", [
      _vm._v(
        "\n    If you have any further questions, please reach out to us at " +
          _vm._s(_vm.website.emails[0]) +
          ".\n    We’re happy to help.\n  "
      ),
    ]),
    _c("p", [
      _vm._v(
        "\n    Thank you for choosing " + _vm._s(_vm.website.name) + ".\n  "
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", [
      _c("li", [
        _vm._v(
          "\n      Select an area code, then choose from several available phone numbers.\n    "
        ),
      ]),
      _c("li", [_vm._v("\n      Give your phone line a name.\n    ")]),
      _c("li", [
        _vm._v(
          "\n      Tell us how you’d like to receive inbound calls.\n    "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }